<template>
  <Dialog custom-class="confirmation" title="Uklanjanje" :visible="isRemoveDialogVisible" :show-close="false">
    <div class="message">
      Jeste li sigurni?
    </div>
    <div class="form">
      <Form :model="formData" ref="componentForm" @submit.native.prevent="submitForm('componentForm')">
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            native-type="submit"
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationReportsRootModalsRemove',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Button,
    Dialog
  },
  props: [
    'operatingItem',
    'isRemoveDialogVisible'
  ],
  data() {
    return {
      formData: {
        item: {
          content: {
            id: '',
            client: {
              id: '',
              content: {
                company: {
                  name: '',
                  address: '',
                  vatNumber: ''
                },
                contact: {
                  name: '',
                  phone: '',
                  mobilePhone: '',
                  email: '',
                }
              }
            },
            vehicle: {
              type: '',
              registrationNumber: '',
              vin: '',
              color: ''
            },
            insurance: {
              id: '',
              content: {
                name: '',
                list: []
              }
            },
            damageReport: '',
            damageList: [],
            additionalInformation: {
              counterStatus: 0,
              hoursWorked: 0,
              reporter: ''
            },
            calculation: {
              damageTotal: 0,
              assemblyDisassembly: 0,
              lacquering: 0,
              parts: 0,
              discount: 0,
              totalWithoutVAT: 0,
              vat: 0,
              totalWithVAT: 0
            },
            notes: {
              note: '',
              internalNote: ''
            }
          },
          createdAt: new Date()
        }
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post(`administration/report/${this.formData.item.id}/remove`).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$emit('getItems', true);
        this.$emit('closeDialog');
      });
    },
    setData(formName) {
      this.formData = {
        item: {
          content: {
            id: '',
            client: {
              id: '',
              content: {
                company: {
                  name: '',
                  address: '',
                  vatNumber: ''
                },
                contact: {
                  name: '',
                  phone: '',
                  mobilePhone: '',
                  email: '',
                }
              }
            },
            vehicle: {
              type: '',
              registrationNumber: '',
              vin: '',
              color: ''
            },
            insurance: {
              id: '',
              content: {
                name: '',
                list: []
              }
            },
            damageReport: '',
            damageList: [],
            additionalInformation: {
              counterStatus: 0,
              hoursWorked: 0,
              reporter: ''
            },
            calculation: {
              damageTotal: 0,
              assemblyDisassembly: 0,
              lacquering: 0,
              parts: 0,
              discount: 0,
              totalWithoutVAT: 0,
              vat: 0,
              totalWithVAT: 0
            },
            notes: {
              note: '',
              internalNote: ''
            }
          },
          createdAt: new Date()
        }
      };
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    operatingItem() {
      this.formData = {
        item: {
          id: this.operatingItem.id,
          content: {
            id: this.operatingItem.content.id,
            client: this.operatingItem.content.client,
            vehicle: this.operatingItem.content.vehicle,
            insurance: this.operatingItem.content.insurance,
            damageReport: this.operatingItem.content.damageReport,
            damageList: this.operatingItem.content.damageList,
            additionalInformation: this.operatingItem.content.additionalInformation,
            calculation: this.operatingItem.content.calculation,
            notes: this.operatingItem.content.notes
          },
          createdAt: this.operatingItem.created_at
        }
      };
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>

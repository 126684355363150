<template>
  <div class="reports">
    <div class="headline">
      <h1 class="title">
        Zapisnici
      </h1>
      <div class="action">
        <router-link :to="{ name: 'AdministrationReportsCreateIndex' }" class="el-button btn-primary">
          <i class="el-icon-plus"></i>
        </router-link>
      </div>
    </div>
    <div class="search">
      <div class="form">
        <Form :model="formData" @submit.native.prevent="getItemsByQuery()">
          <FormItem prop="query" label="Pretraga">
            <Input
                v-model="formData.query"/>
          </FormItem>
        </Form>
      </div>
    </div>
    <div class="items" v-if="isDataPrepared">
      <div class="item" v-for="item in items" :key="item.id">
        <div class="report">
          <span class="id">
            {{ item.content.id }}
          </span>
          <span class="createdAt">
            {{ item.created_at | moment("DD.MM.YYYY.") }}
          </span>
        </div>
        <div class="name">
          {{ item.content.client.content.contact.name }}
        </div>
        <div class="action">
          <router-link :to="{ name: 'AdministrationReportsPreviewIndex', params: { id: item.id } }" class="el-button btn-light">
            <i class="el-icon-view"></i>
          </router-link>
          <router-link :to="{ name: 'AdministrationReportsUpdateIndex', params: { id: item.id } }" class="el-button btn-light">
            <i class="el-icon-edit"></i>
          </router-link>
          <Button class="btn-light" @click="removeItem(item)">
            <i class="el-icon-close"></i>
          </Button>
        </div>
      </div>
    </div>
    <div class="load-more" v-if="!loading && page < lastPage && formData.query.length === 0">
      <Button class="btn-light" @click="loadMore()">
        Učitajte više
      </Button>
    </div>

    <AdministrationReportsRootModalsRemove
      :isRemoveDialogVisible="isRemoveDialogVisible"
      :operatingItem="operatingItem"
      @getItems="getItems"
      @closeDialog="isRemoveDialogVisible = false"/>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AdministrationReportsRootModalsRemove from '@/views/Administration/Reports/Root/Modals/Remove';

// Element UI
import {Form, FormItem, Input, Button} from 'element-ui';

// Logic
export default {
  name: 'AdministrationReportsRootIndex',
  mixins: [Common],
  components: {
    AdministrationReportsRootModalsRemove,
    Form,
    FormItem,
    Input,
    Button
  },
  data() {
    return {
      perPage: 24,
      page: 1,
      lastPage: 1,
      formData: {
        query: ''
      },
      operatingItem: {
        item: {
          content: {
            id: '',
            client: {
              id: '',
              content: {
                company: {
                  name: '',
                  address: '',
                  vatNumber: ''
                },
                contact: {
                  name: '',
                  phone: '',
                  mobilePhone: '',
                  email: '',
                }
              }
            },
            vehicle: {
              type: '',
              registrationNumber: '',
              vin: '',
              color: ''
            },
            insurance: {
              id: '',
              content: {
                name: '',
                list: []
              }
            },
            damageReport: '',
            damageList: [],
            additionalInformation: {
              counterStatus: 0,
              reporter: ''
            },
            calculation: {
              damageTotal: 0,
              assemblyDisassembly: 0,
              lacquering: 0,
              discount: 0,
              totalWithoutVAT: 0,
              vat: 0,
              totalWithVAT: 0
            },
            notes: {
              note: '',
              internalNote: ''
            }
          },
          createdAt: new Date()
        }
      },
      items: [],
      isRemoveDialogVisible: false
    }
  },
  watch: {
    'formData.query'() {
      if (this.formData.query.length) {
        this.getItemsByQuery();
      } else {
        this.page = 1;
        this.lastPage = 1;
        this.getItems();
      }
    }
  },
  methods: {
    getItemsByQuery() {
      this.setLoadingComponent(true);
      this.Axios.get('administration/report/search', {
        params: {
          query: this.formData.query
        }
      }).then(response => {
        if ((response.data.data) && response.data.data.length > 0) {
          this.items = response.data.data;
        } else {
          this.items = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },

    getItems() {
      this.setLoadingComponent(true);
      this.setPaginationData();
      this.Axios.get('administration/report/get-items', {
        params: {
          perPage: this.perPage,
          page: this.page
        }
      }).then(response => {
        if (response.data.content.data.data) {
          this.items = response.data.content.data.data;
          this.lastPage = response.data.content.data.last_page
        } else {
          this.items = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.items = [];
          this.setPaginationData();
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    getItemsPagination() {
      this.setLoadingComponent(true);
      this.Axios.get('administration/report/get-items', {
        params: {
          perPage: this.perPage,
          page: this.page
        }
      }).then(response => {
        if (response.data.content.data.data) {
          this.lastPage = response.data.content.data.last_page
          response.data.content.data.data.map(item => {
            this.items.push(item);
          });
        } else {
          this.items = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.items = [];
          this.setPaginationData();
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    removeItem(item) {
      this.operatingItem = item;
      this.isRemoveDialogVisible = true;
    },
    setPaginationData() {
      this.perPage = 24;
      this.page = 1;
      this.lastPage = 1;
    },
    loadMore() {
      this.page++;
      this.getItemsPagination();
    }
  },
  created() {
    this.initialize();
    this.getItems();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.reports {
  .form {
    margin-top: 24px;
  }

  .items {
    margin-top: 32px;
    margin-bottom: 48px;

    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $grey-100;
      padding-bottom: 16px;
      margin-bottom: 16px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .name, .report {
        min-width: 160px;

        span {
          display: block;

          &.createdAt {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }

      .action {
        flex: 1;
        text-align: right;

        .el-button {
          width: 48px;
          min-width: unset;
          height: 48px;
        }
      }
    }
  }

  .load-more {
    margin-top: 24px;
    text-align: center;
  }
}
</style>
